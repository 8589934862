import { Badge } from "reactstrap"
import { redirect } from "react-router-dom"
import { useState } from "react"
import { components } from "react-select"
import { getCookieByName } from "./myApiCenter"

export const InputOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
   const [isActive, setIsActive] = useState(false)
   const onMouseDown = () => setIsActive(true)
   const onMouseUp = () => setIsActive(false)
   const onMouseLeave = () => setIsActive(false)

   // styles
   let bg = "transparent"
   if (isFocused) bg = "#eee"
   if (isActive) bg = "#B2D4FF"

   const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex "
   }

   // prop assignment
   const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
   }

   return (
      <components.Option {...rest} isDisabled={isDisabled} isFocused={isFocused} isSelected={isSelected} getStyles={getStyles} innerProps={props}>
         <input type="checkbox" readOnly checked={isSelected} />
         &nbsp; &nbsp; {children}
      </components.Option>
   )
}
export const customStyles = {
   rows: {
      style: {
         whiteSpace: "pre-line"
      },
      striped: {
         backgroundColor: "lightgray" // Set the background color for striped rows
      }
   },
   headcells: {
      style: {
         // justifyContent: "center",
         fontSize: "24px",
         lineHeight: "15px",
         letterSpacing: "1px",
         color: "#515C68",
         fontFamily: "Space Grotesk",
         fontStyle: "normal",
         fontWeight: "500",
         whiteSpace: "pre-line",
         backgroundColor: "#f9fafb",
         textTransform: "uppercase"
      }
   },
   cells: {
      style: {
         // justifyContent: "center",
         fontSize: "15px",
         lineHeight: "18px",
         letterSpacing: "1px",
         fontFamily: "Space Grotesk",
         fontStyle: "normal",
         fontWeight: "400",
         paddingLeft: "10px",
         // lineHeight: '21px',
         color: "#1B222B"
      }
   },
   table: {
      style: {
         minHeight: "50vh"
      }
   }
}
export const dummyItemOptions = [
   {
      value: 1,
      label: "BANKNIFTY",
      color: [
         { color: "green", quantity: 0 },
         { color: "kesari", quantity: 0 }
      ],
      price: 25,
      totalPrice: 0,
      totalQuantity: 0
   },
   {
      value: 2,
      label: "CRUDE",
      color: [
         { color: "blue", quantity: 0 },
         { color: "red", quantity: 0 },
         { color: "yellow", quantity: 0 }
      ],
      price: 26,
      totalPrice: 0,
      totalQuantity: 0
   },
   {
      value: 3,
      label: "shal",
      color: [
         { color: "gray", quantity: 0 },
         { color: "neon", quantity: 0 }
      ],
      price: 27,
      totalPrice: 0,
      totalQuantity: 0
   },
   {
      value: 4,
      label: "short",
      color: [
         { color: "light gray", quantity: 0 },
         { color: "bilu", quantity: 0 }
      ],
      price: 27,
      totalPrice: 0,
      totalQuantity: 0
   }
]

export const exchangeOption = [
   {
      value: "MCX",
      label: "MCX"
   },
   {
      value: "NFO",
      label: "NFO"
   },
   {
      value: "NSE",
      label: "NSE"
   }
]
export const indexNameOption = [
   {
      value: "NIFTY",
      label: "NIFTY"
   },
   {
      value: "FINNIFTY",
      label: "FINNIFTY"
   },
   {
      value: "BANKNIFTY",
      label: "BANKNIFTY"
   },
   {
      value: "MIDCPNIFTY",
      label: "MIDCPNIFTY"
   }
]
export const orderStatusOption = [
   {
      value: "completed",
      label: "Completed"
   },
   {
      value: "pending",
      label: "Pending"
   },
   {
      value: "open",
      label: "Open"
   },
   {
      value: "working",
      label: "Working"
   },
   {
      value: "cancelled",
      label: "Cancelled"
   },
   {
      value: "rejected",
      label: "Rejected"
   }
]
export const capitalOption = [
   {
      value: 0,
      label: "0"
   },
   {
      value: 5000,
      label: "5000"
   },
   {
      value: 10000,
      label: "10000"
   },
   {
      value: 15000,
      label: "15000"
   },
   {
      value: 20000,
      label: "20000"
   },
   {
      value: 80000,
      label: "80000"
   },
   {
      value: 30000,
      label: "30000"
   },
   {
      value: 35000,
      label: "35000"
   },
   {
      value: 50000,
       label: "50000"
   },
   {
      value: 100000,
      label: "100000"
   }
]
export const activeTradeStatusOption = [
   {
      value: "CALL",
      label: "CALL"
   },
   {
      value: "PUT",
      label: "PUT"
   },
   {
      value: "NONE",
      label: "NONE"
   }
]
export const tradeStatusOption = [
   {
      value: "BUY",
      label: "BUY",
      customStyle: { color: "white", backgroundColor: "green", fontWeight: "bold" }
   },
   {
      value: "SELL",
      label: "SELL",
      customStyle: { color: "white", backgroundColor: "red", fontWeight: "bold" }
   }
]
export const tradeStatus = {
   true: (
      <span
         className="border-rounded "
         style={{ background: "green", textAlign: "center", color: "white", padding: "2px 15px", borderRadius: "15px" }}
      >
         Active
      </span>
   ),
   false: (
      <span className="border-rounded " style={{ background: "red", color: "white", textAlign: "center", padding: "2px 15px", borderRadius: "15px" }}>
         Inactive
      </span>
   )
}
export const optionOrderStatus = {
   pending: (
      <span
         className="border-rounded "
         style={{ background: "yellow", textAlign: "center", color: "black", padding: "2px 15px", borderRadius: "15px" }}
      >
         Pending
      </span>
   ),
   running: (
      <span
         className="border-rounded "
         style={{ background: "blue", color: "white", textAlign: "center", padding: "2px 15px", borderRadius: "15px" }}
      >
         Running
      </span>
   ),
   targetHitted: (
      <span
         className="border-rounded "
         style={{ background: "green", color: "white", textAlign: "center", padding: "2px 15px", borderRadius: "15px" }}
      >
         Target Hit
      </span>
   ),
   candleTargetHitted: (
      <span
         className="border-rounded "
         style={{ background: "green", color: "white", textAlign: "center", padding: "2px 15px", borderRadius: "15px" }}
      >
         Candle Target Hit
      </span>
   ),
   stopLossHitted: (
      <span className="border-rounded " style={{ background: "red", color: "white", textAlign: "center", padding: "2px 15px", borderRadius: "15px" }}>
         Stop Loss Hit
      </span>
   )
}

export const isCurrentTimeInRange = () => {
   // Get the current time in the Indian time zone
   const now = new Date()
   const indiaTime = new Intl.DateTimeFormat("en-US", {
      timeZone: "Asia/Kolkata",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit"
   }).format(now)

   // Parse the time into hours and minutes
     const [hours, minutes] = indiaTime.split(":").map(Number)

   // Define the start and end times in 24-hour format
   const startTime = { hours: 10, minutes: 45 }
   const endTime = { hours: 15, minutes: 30 }

   // Check if the current time is within the range
   if (
      (hours > startTime.hours || (hours === startTime.hours && minutes >= startTime.minutes)) &&
      (hours < endTime.hours || (hours === endTime.hours && minutes <= endTime.minutes))
   ) {
      return true
   } else {
      return false
   }
}
export const isCurrentDayInRange = () => {
   // Get the current date and time in the Indian time zone
   const now = new Date()
   const indiaTime = new Intl.DateTimeFormat("en-US", {
      timeZone: "Asia/Kolkata",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      weekday: "short" // To get the day of the week
   }).formatToParts(now)

   // Extract the hours, minutes, and weekday
   const hours = Number(indiaTime.find((part) => part.type === "hour").value)
   const minutes = Number(indiaTime.find((part) => part.type === "minute").value)
   const day = indiaTime.find((part) => part.type === "weekday").value

   // Define the start and end times in 24-hour format
   const startTime = { hours: 9, minutes: 15 }
   const endTime = { hours: 15, minutes: 30 }

   // Define the valid weekdays
   const validWeekdays = ["Mon", "Tue", "Wed", "Thu", "Fri"]

   // Check if the current time is within the range and the day is between Monday and Friday
   if (
      validWeekdays.includes(day) &&
      (hours > startTime.hours || (hours === startTime.hours && minutes >= startTime.minutes)) &&
      (hours < endTime.hours || (hours === endTime.hours && minutes <= endTime.minutes))
   ) {
      return true
   } else {
      return false
   }
}
export const socketConnectionStatus = {
   true: (
      <span
         className="border-rounded "
         style={{ background: "lightgreen", textAlign: "center", color: "black", padding: "2px 15px", borderRadius: "15px" }}
      >
         Connected
      </span>
   ),
   false: (
      <span className="border-rounded " style={{ background: "red", color: "white", textAlign: "center", padding: "2px 15px", borderRadius: "15px" }}>
         Disconnected
      </span>
   )
}
export const dailyTradeStatus = {
   true: (
      <span
         className="border-rounded "
         style={{ background: "lightgreen", textAlign: "center", color: "black", padding: "4px 15px", borderRadius: "15px" }}
      >
         On
      </span>
   ),
   false: (
      <span className="border-rounded " style={{ background: "red", color: "white", textAlign: "center", padding: "4px 15px", borderRadius: "15px" }}>
         Off
      </span>
   )
}
export const userStatus = {
   true: (
      <Badge pill color="light-success">
         <span className="align-middle ms-25">Active</span>
      </Badge>
   ),
   false: (
      <Badge pill color="light-danger">
         <span className="align-middle ms-25">Inactive</span>
      </Badge>
   )
}
export const clearAllCookies = () => {
   var cookies = document.cookie.split("; ")
   for (var i = 0; i < cookies.length; i++) {
      var cookieParts = cookies[i].split("=")
      var cookieName = cookieParts[0]
      document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
   }
}
export const userloader = () => {
   const user = JSON.parse(localStorage.getItem("user"))
   const token = getCookieByName("token1")
   // console.log(user.userRole)
   // console.log(token)
   if (user.userRole === "admin" && token) {
      return null
   } else if (user.userRole === "user" && token) {
      return redirect("/dashboard")
   } else {
      return redirect("/login")
   }
}
export const isoToLocale = (isoString) => {
   

   // Convert to locale string
   // console.log(localeString.split("at")[1].split("GMT")[0])
   // console.log(localeString)
   // Format the date string
   const formattedDateString = `${isoString.split("T")[0].split("-").reverse().join("/")} `

   return formattedDateString
   // console.log(localeString)
}
export const userAccessloader = () => {
   const user = JSON.parse(localStorage.getItem("user"))
   const token = getCookieByName("token1")
   // console.log(user?.userRole)
   // console.log(token)
   if (user?.userRole === "admin" && token) {
      return null
   } else if (user?.userRole === "user" && token) {
      return null
   } else {
      return redirect("/login")
   }
}
export const managerAccessloader = () => {
   const user = JSON.parse(localStorage.getItem("user"))
   const token = getCookieByName("token1")
   // console.log(user?.userRole)
   // console.log(token)
   if (user?.userRole === "admin" && token) {
      return null
   } else if (user?.userRole === "manager" && token) {
      return null
   } else {
      return redirect("/login")
   }
}
