import { useContext } from "react"
import { Input } from "reactstrap"
import { AuthContext } from "../../context/Can"
import toast from "react-hot-toast"
import { capitalOption } from "../../utils"
const StockWiseQuantity = (props) => {
   console.log(props.data)
   const [userData, ] = useContext(AuthContext)
   const oldCapital = props.capitalAry[props.index]
   const oldPercentage = props.percentageAry[props.index]
   const oldActive = props.activeAry[props.index]
   const oldQuantity = props.quantityAry[props.index]
   console.log(props.data)
   const capitalChangeHandler = (e) => {
      // console.log(+value[0].value)
      console.log(e.target.value)
      if (userData.userRole === "admin") {
         props.setdata((prev) => {
            const oldStrDetails = [...prev.strDetail]
            oldStrDetails[props.index].capital = +e.target.value
            // console.log(stockDetails)
            return { ...prev, strDetail: oldStrDetails }
         })
      } else if (userData.userRole === "user") {
         props.setdata((prev) => {
            const oldStrDetails = [...prev.strDetail]
            if (oldCapital >= +e.target.value) {
               oldStrDetails[props.index].capital = +e.target.value
               // console.log(stockDetails)
               return { ...prev, strDetail: oldStrDetails }
            } else {
               toast.error("you can not increse Capital please contact admin")
               return { ...prev }
            }
         })
      }
   }
   const quantityChangeHandler = (e) => {
      // console.log(+value[0].value)
      console.log(e.target.value)
      if (userData.userRole === "admin") {
         props.setdata((prev) => {
            const oldStrDetails = [...prev.strDetail]
            oldStrDetails[props.index].quantity = +e.target.value
            // console.log(stockDetails)
            return { ...prev, strDetail: oldStrDetails }
         })
      } else if (userData.userRole === "user") {
         props.setdata((prev) => {
            const oldStrDetails = [...prev.strDetail]
            if (oldQuantity >= +e.target.value) {
               oldStrDetails[props.index].quantity = +e.target.value
               // console.log(stockDetails)
               return { ...prev, strDetail: oldStrDetails }
            } else {
               toast.error("you can not increse Quantity please contact admin")
               return { ...prev }
            }
         })
      }
   }
   const percentageChangeHandler = (value) => {
      if (userData.userRole === "admin") {
         props.setdata((prev) => {
            const oldStrDetails = [...prev.strDetail]
            oldStrDetails[props.index].percentageProfit = +value
            // console.log(stockDetails)
            return { ...prev, strDetail: oldStrDetails }
         })
      } else if (userData.userRole === "user") {
         props.setdata((prev) => {
            const oldStrDetails = [...prev.strDetail]
            if (oldPercentage >= +value) {
               oldStrDetails[props.index].percentageProfit = +value
               // console.log(stockDetails)
               return { ...prev, strDetail: oldStrDetails }
            } else {
               toast.error("you can not increse Capital please contact admin")
               return { ...prev }
            }
         })
      }
   }
   const activeHandler = (e) => {
      // console.log(e.target.checked)
      if (userData.userRole === "admin") {
         props.setdata((prev) => {
            const oldStrDetails = [...prev.strDetail]
            oldStrDetails[props.index].isActive = e.target.checked
            // console.log(stockDetails)
            return { ...prev, strDetail: oldStrDetails }
         })
      } else if (userData.userRole === "user") {
         if (!e.target.checked || oldActive) {
            props.setdata((prev) => {
               const oldStrDetails = [...prev.strDetail]
               oldStrDetails[props.index].isActive = e.target.checked
               // console.log(stockDetails)
               return { ...prev, strDetail: oldStrDetails }
            })
         } else {
            toast.error("please contact admin for active")
         }
      }
   }

   // console.log(item)
   return (
      <div className=" p-3  mt-2 rounded my-stockwise-quantity">
         <h5>{props.data.label}</h5>
         {userData.userRole === "admin" && (
            <div className="d-flex gap-2 flex-row me-auto m-1 p-1 w-50 flex-wrap">
               <div className="d-flex gap-2 flex-row align-items-center">
                  <span>Profit Percentage</span>
                  <input
                     autoFocus
                     value={props.data.percentageProfit}
                     style={{ width: "80px", height: "30px" }}
                     onChange={(e) => percentageChangeHandler(e.target.value)}
                     min={0}
                     type="number"
                  />
               </div>
            </div>
         )}

         <div className="d-flex gap-2 flex-row me-auto m-1 p-1 w-50 flex-wrap">
            <div className="d-flex gap-2 flex-row align-items-center">
               <span>Quantity(x Default Quantity)</span>
               <input
                  autoFocus
                  value={props.data.quantity}
                  style={{ width: "80px", height: "30px" }}
                  onChange={quantityChangeHandler}
                  min={0}
                  type="number"
               />
            </div>
         </div>
         {userData.userRole === "admin" && (
            <div className="d-flex gap-2 flex-row me-auto m-1 p-1 w-50 flex-wrap">
               <div className="d-flex gap-2 flex-row">
                  <span>Capital </span>
                  <select value={props.data?.capital} onChange={capitalChangeHandler}>
                     {capitalOption.map((opt) => {
                        return (
                           <option key={opt.value} value={opt.value}>
                              {opt.label}
                           </option>
                        )
                     })}
                  </select>
               </div>
            </div>
         )}

         <div className="me-auto d-flex align-items-center" style={{ width: "350px" }}>
            <p className="h4 me-2 ">Active</p>
            <div className=" form-switch form-check-success">
               <Input
                  type="switch"
                  id=""
                  onChange={activeHandler}
                  name="success"
                  // disabled={userData.userRole === "user"}
                  checked={props.data.isActive}
               />
            </div>
         </div>
      </div>
   )
}
export default StockWiseQuantity
