import { Col, Button, ModalFooter, ModalBody } from "reactstrap"
import { useState, useEffect } from "react"
import { toast } from "react-hot-toast"
import { getOrdersOfUsers } from "../../myApiCenter"
import UpdateOrder from "./UpdateOrder"

const UserOrders = (props) => {
   const [data, setdata] = useState(null)
   const getOrderData = async () => {
      // console.log(password)
      try {
         // setloading(true)
         const j = await getOrdersOfUsers(props.userId)
         // console.log(j)
         const openOrders = j?.filter((e) => e.status === "open")
         if (openOrders?.length > 0) {
            setdata(openOrders)
         } else {
            toast.error("there is no open order found")
            props.setisModelOpen(false)
         }
      } catch (err) {
         console.log(err)
      } finally {
         // setloading(false)
         // toast.dismiss()
      }
   }

   useEffect(() => {
      getOrderData()
   },)
   // console.log(data)
   // console.log(stockOption)
   return (
      <>
         <div className="w-100">
            <Col>
               <ModalBody>
                  {data?.length > 0 &&
                     data?.map((e, index) => {
                        return <UpdateOrder key={index} data={e} userId={props.userId} getOrderData={getOrderData} />
                     })}
               </ModalBody>
               <ModalFooter>
                  <div className="me-auto">
                     <Button
                        className="me-auto ms-2 rounded-pill"
                        outline
                        color="secondary"
                        onClick={() => {
                        }}
                     >
                        Cancel
                     </Button>
                  </div>
               </ModalFooter>
            </Col>
         </div>
      </>
   )
}
export default UserOrders
