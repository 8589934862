import { optionOrderStatus, tradeStatus } from "../../utils";

const CallPutData = (props) => {
  const data = props.data;

  return (
    <table className="call-put-table">
      <thead>
        <tr>
          <th></th>
          <th className="call">CALL</th>
          <th className="put">PUT</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Strike Price</th>
          <td className="call">
            {data?.call?.brokerDetail?.tradingsymbol?.slice(-7, -2)}
          </td>
          <td className="put">
            {data?.put?.brokerDetail?.tradingsymbol?.slice(-7, -2)}
          </td>
        </tr>
        <tr>
          <th>Trading Symbol</th>
          <td className="call">{data?.call?.brokerDetail?.tradingsymbol}</td>
          <td className="put">{data?.put?.brokerDetail?.tradingsymbol}</td>
        </tr>
        <tr>
          <th>Expiry</th>
          <td className="call">
            {data?.call?.brokerDetail?.expiry.slice(0, 2)}{' '}
            {data?.call?.brokerDetail?.expiry?.slice(2, 5)}{' '}
            {data?.call?.brokerDetail?.expiry.slice(5, 9)}
          </td>
          <td className="put">
            {data?.put?.brokerDetail?.expiry.slice(0, 2)}{' '}
            {data?.put?.brokerDetail?.expiry?.slice(2, 5)}{' '}
            {data?.put?.brokerDetail?.expiry.slice(5, 9)}
          </td>
        </tr>
        <tr>
          <th>ltp</th>
          <td className="call">{data?.call?.ltp}</td>
          <td className="put">{data?.put?.ltp}</td>
        </tr>
        <tr>
          <th>Trigger Value</th>
          <td className="call">{data?.call?.triggerValueForBuy}</td>
          <td className="put">{data?.put?.triggerValueForBuy}</td>
        </tr>
        <tr>
          <th>Target Value</th>
          <td className="call">{data?.call?.targetValue}</td>
          <td className="put">{data?.put?.targetValue}</td>
        </tr>

        <tr>
          <th>Day High</th>
          <td className="call">{data?.call?.dayHigh}</td>
          <td className="put">{data?.put?.dayHigh}</td>
        </tr>
        <tr>
          <th>Candle High</th>
          <td className="call">{data?.call?.candleHigh}</td>
          <td className="put">{data?.put?.candleHigh}</td>
        </tr>
        <tr>
          <th>Stop Loss Value</th>
          <td className="call">{data?.call?.stopLossValue}</td>
          <td className="put">{data?.put?.stopLossValue}</td>
        </tr>
        <tr>
          <th>Lot Size</th>
          <td className="call">{data?.call?.brokerDetail?.lotsize}</td>
          <td className="put">{data?.put?.brokerDetail?.lotsize}</td>
        </tr>
        <tr>
          <th>Quantity</th>
          <td className="call">{data?.call?.quantity}</td>
          <td className="put">{data?.put?.quantity}</td>
        </tr>
        <tr>
          <th>Quantity For Call SL</th>
          <td className="call">{data?.call?.quantityForCallStopLoss}</td>
          <td className="put">{data?.put?.quantityForCallStopLoss}</td>
        </tr>
        <tr>
          <th>Quantity For Put SL</th>
          <td className="call">{data?.call?.quantityForPutStopLoss}</td>
          <td className="put">{data?.put?.quantityForPutStopLoss}</td>
        </tr>
        <tr>
          <th>Option Status</th>
          <td className="call">{tradeStatus[data.call?.isActive]}</td>
          <td className="put">{tradeStatus[data.put?.isActive]}</td>
        </tr>
        <tr>
          <th>Order Status</th>
          <td className="call">{optionOrderStatus[data.call?.orderStatus]}</td>
          <td className="put">{optionOrderStatus[data.put?.orderStatus]}</td>
        </tr>
        {/* <tr>
               <th>Target Hit</th>
               <td className="call">
                  <Input defaultChecked={data.call?.isTargetHit} disabled type="checkbox" />
               </td>
               <td className="put">
                  <Input defaultChecked={data.put?.isTargetHit} disabled type="checkbox" />
               </td>
            </tr>
            <tr>
               <th>Stop loss Hit</th>
               <td className="call">
                  <Input defaultChecked={data.call?.isStopLossHit} style={{ width: "17px", height: "17px" }} disabled type="checkbox" />
               </td>
               <td className="put">
                  <Input defaultChecked={data.put?.isStopLossHit} style={{ width: "17px", height: "17px", color: "blue" }} disabled type="checkbox" />
               </td>
            </tr> */}
      </tbody>
    </table>
  );
};

export default CallPutData;
